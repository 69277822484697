<template>
  <div class="container" id="top-page">
    <v-expansion-panels multiple v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="Header"
          >My Subcontract Jobs</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div
            v-if="loading"
            style="position: absolute; width: 95%; height: 90%"
          >
            <div
              style="
                display: flex;
                flex: 1 1 auto;
                justify-content: center;
                align-items: center;
                height: 100%;
              "
            >
              <Loading :color="'#006680'" :secondaryColor="'#006680'"></Loading>
            </div>
          </div>
          <h4 style="font-weight: bold">Search:</h4>
          <input
            class="form-control border input-search"
            id="myInput"
            type="text"
            v-model="search1"
          />
          <br />
          <v-data-table
            hide-default-footer
            :items-per-page="5"
            :headers="jobListHeader"
            :items="headerItemsFilterDate"
            class="elevation-1 table-header"
            @click:row="handleRowClick"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              />
              <v-dialog v-model="dialog" max-width="750px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Job Information</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div v-for="(header, idx) in jobListHeader" :key="idx">
                          <v-col cols="auto" sm="auto" md="auto">
                            <v-text-field
                              color="black"
                              v-if="checkIsEmpty(curJob[header.value])"
                              v-model="emptyItem"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                            <v-text-field
                              color="black"
                              v-else
                              v-model="curJob[header.value]"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                          </v-col>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="close">
                      OK
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="seeDetail">
                      See Operations in this Job
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template
              v-for="header in jobListHeader"
              v-slot:[`item.${header.value}`]="{ item }"
            >
              <template v-if="isBoolean(item[header.value])">
                <div class="checkbox_container" v-bind:key="item[header.value]">
                  <v-checkbox
                    v-bind:key="item[header.value]"
                    v-model="item[header.value]"
                    :true-value="true"
                    :false-value="false"
                    light
                    disabled
                    color="black"
                  ></v-checkbox>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel id="detail">
        <v-expansion-panel-header class="Header"
          >Operations in Job:
          {{ curJob.JobHead_JobNum }}</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <h4 style="font-weight: bold">Search:</h4>
          <input
            class="form-control border input-search"
            id="myInput"
            type="text"
            v-model="search2"
          />
          <br />
          <v-data-table
            hide-default-footer
            :items-per-page="5"
            :headers="operListHeader"
            :items="detailItemsFilter"
            class="elevation-1 table-header"
            @click:row="handleRowClick1"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              />
              <v-dialog v-model="dialog1" max-width="750px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Operation Information</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div v-for="(header, idx) in operListHeader" :key="idx">
                          <v-col cols="auto" sm="auto" md="auto">
                            <v-checkbox
                              v-if="header.text == 'Subcontract Complete'"
                              :label="header.text"
                              color="black"
                              v-model="complete"
                              :true-value="true"
                              :false-value="false"
                              light
                            ></v-checkbox>
                            <v-text-field
                              color="black"
                              v-else-if="checkIsEmpty(curOper[header.value])"
                              v-model="emptyItem"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                            <v-text-field
                              color="black"
                              v-else
                              v-model="curOper[header.value]"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                          </v-col>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="close">
                      OK
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Save
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      download
                      target="_blank"
                      href="https://www.mitsubishi-electric.co.nz/materials/Aircon/Info_Guides/Cleaning-Your-Heat-Pump.pdf"
                    >
                      Check Attachment
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template
              v-for="header in operListHeader"
              v-slot:[`item.${header.value}`]="{ item }"
            >
              <template v-if="isBoolean(item[header.value])">
                <div class="checkbox_container" v-bind:key="item[header.value]">
                  <v-checkbox
                    v-bind:key="item[header.value]"
                    v-model="item[header.value]"
                    :true-value="true"
                    :false-value="false"
                    light
                    disabled
                    color="black"
                  ></v-checkbox>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <tableComponent style="display: none" :tableHeaders="[]" :tableItems="[]">
    </tableComponent>
  </div>
</template>

<script>
import tableComponent from "@/components/table/tableComponent.vue";
import { mapGetters } from "vuex";
import DecodeJWT from "@/utils/DecodeJWT";
import Loading from "../../loading/Loading.vue";
import { callBAQ, callMethodFunc } from "@/store/Method/index";

export default {
  components: { tableComponent, Loading },
  mounted() {
    let token = localStorage.getItem("MTNToken");
    if (token == "" || !token) {
      this.$router.push("/login");
    } else {
      var info = DecodeJWT(token);
      if (info.exp <= new Date().getTime() / 1000) {
        this.$router.push("/login");
      } else {
        window.scrollTo({
          top: document.getElementById("top-page").offsetTop,
          left: 0,
          behavior: "smooth",
        });
        this.$store.dispatch("Data/callMethodAttachment", {
          curJob: this.curJob,
          curOper: this.curOper,
          complete: this.complete,
        });
        this.panel = Array.from(Array(3).keys());
        this.getBAQ();
        const today = new Date();
        const fromday = new Date();
        fromday.setMonth(fromday.getMonth() - 3);
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, "0");
        let day = today.getDate().toString().padStart(2, "0");
        this.todate1 = this.todate2 = `${year}-${month}-${day}`;
        year = fromday.getFullYear();
        month = (fromday.getMonth() + 1).toString().padStart(2, "0");
        day = fromday.getDate().toString().padStart(2, "0");
        this.fromdate1 = this.fromdate2 = `${year}-${month}-${day}`;
      }
    }
  },
  data() {
    return {
      complete: false,
      headersNotfound: [{ text: "", value: "nf" }],
      itemNotfound: [{ nf: "Data not found!" }],
      jobListHeader: [
        { text: "Company", value: "JobHead_Company", sortable: false },
        { text: "JobNum", value: "JobHead_JobNum", sortable: false },
        { text: "Complete", value: "JobHead_JobComplete", sortable: false },
        { text: "Engineered", value: "JobHead_JobEngineered", sortable: false },
        { text: "Part", value: "JobHead_PartNum", sortable: false },
        {
          text: "Description",
          value: "JobHead_PartDescription",
          sortable: false,
        },
        { text: "Start", value: "JobHead_StartDate", sortable: false },
        { text: "Date", value: "JobHead_DueDate", sortable: false },
        { text: "Equipment ID", value: "JobHead_EquipID", sortable: false },
      ],
      operListHeader: [
        { text: "Opr", value: "JobOper_OprSeq", sortable: false },
        { text: "Completed", value: "JobOper_OpComplete", sortable: false },
        { text: "Operation", value: "JobOper_OpCode", sortable: false },
        {
          text: "Operation Description",
          value: "JobOper_OpDesc",
          sortable: false,
        },
        { text: "Assembly Seq", value: "JobOper_AssemblySeq", sortable: false },
        { text: "Part", value: "JobOper_PartNum", sortable: false },
        { text: "Part Desc", value: "JobOper_Description", sortable: false },
        { text: "UOM", value: "JobOper_IUM", sortable: false },
        { text: "Start Date", value: "JobOper_StartDate", sortable: false },
        { text: "Due Date", value: "JobOper_DueDate", sortable: false },
        {
          text: "Subcontract Complete",
          value: "JobOper_SubComplete",
          sortable: false,
        },
      ],
      headerItems: [],
      detailItems: [],
      panel: [],
      curJob: {},
      curOper: {},
      dialog: false,
      dialog1: false,
      emptyItem: " ",
      search1: "",
      search2: "",
      fromdate1: "",
      todate1: "",
      fromdate2: "",
      todate2: "",
      loading: false,
      jobListData: [],
      operationListData: [],
    };
  },
  watch: {},
  computed: {
    ...mapGetters("User", ["getUserInfo"]),
    headerItemsFilter() {
      const filterKey = (this.search1 || "").toLowerCase();
      if (this.headerItems == null || filterKey === "") return this.headerItems;

      return this.headerItems.filter((i) => {
        return (
          i.JobHead_JobNum.toString().toLowerCase().indexOf(filterKey) > -1
        );
      });
    },
    headerItemsFilterDate() {
      if (this.headerItemsFilter.length > 0) {
        // let from = new Date(this.fromdate1);
        // let to = new Date(this.todate1)
        // return this.headerItemsFilter.filter((i) => {
        //   let day = i.JobHead_DueDate.split('/')[0];
        //   let month = i.JobHead_DueDate.split('/')[1];
        //   let year = i.JobHead_DueDate.split('/')[2];
        //   let date = new Date(year, month-1, day);
        //   return date <= to && from <= date;
        // });
        return this.headerItemsFilter;
      } else {
        return this.headerItemsFilter;
      }
    },
    detailItemsFilter() {
      const filterKey = (this.search2 || "").toLowerCase();
      if (this.detailItems == null || filterKey === "") return this.detailItems;
      return this.detailItems.filter((i) => {
        return (
          i.JobOper_OprSeq.toString().toLowerCase().indexOf(filterKey) > -1 ||
          i.JobOper_OpCode.toString().toLowerCase().indexOf(filterKey) > -1
        );
      });
    },
    detailItemsFilterDate() {
      if (this.detailItemsFilter.length > 0) {
        // let from = new Date(this.fromdate2);
        // let to = new Date(this.todate2);
        // return this.detailItemsFilter.filter((i) => {
        //   let day = i.HDCase_CreatedDate.split("/")[0];
        //   let month = i.HDCase_CreatedDate.split("/")[1];
        //   let year = i.HDCase_CreatedDate.split("/")[2];
        //   let date = new Date(year, month - 1, day);
        //   return date <= to && from <= date;
        // });
        return this.detailItemsFilter;
      } else {
        return this.detailItemsFilter;
      }
    },
  },
  methods: {
    async save() {
      let bo = "Erp.BO.JobEntry";
      let method1 = "GetByID";
      let body1 = {
        jobNum: this.curJob.JobHead_JobNum,
      };
      await callMethodFunc(bo, method1, body1).then(async (res1) => {
        if (res1 && res1.message == "error") {
          alert("res1" + res1.error);
        } else {
          let returnObj = JSON.parse(JSON.parse(res1.data).data).returnObj;
          let method2 = "Update";
          let body2 = {
            ds: {
              ...returnObj,
              JobOper: [
                {
                  ...returnObj.JobOper.filter((i) => {
                    return (
                      i.JobNum == this.curJob.JobHead_JobNum &&
                      i.OprSeq == this.curOper.JobOper_OprSeq
                    );
                  })[0],
                  RowMod: "U",
                  Sub_Complete_c: this.complete,
                },
              ],
            },
          };
          await callMethodFunc(bo, method2, body2).then(async (res2) => {
            if (res2 && res2.message == "error") {
              alert("res2" + res2.error);
            } else {
              this.close();
              await this.getBAQ();
            }
          });
        }
      });
    },
    close() {
      this.dialog = false;
      this.dialog1 = false;
    },
    setResize() {
      var tables = document.getElementsByTagName("table");
      for (var i = 0; i < tables.length; i++) {
        this.resizableGrid(tables[i]);
      }
    },
    resizableGrid(table) {
      var row = table.getElementsByTagName("tr")[0],
        cols = row.getElementsByClassName("text-start");
      if (!cols) return;
      table.style.overflow = "hidden";
      for (var i = 0; i < cols.length; i++) {
        var div = createDiv(50);
        cols[i].appendChild(div);
        cols[i].style.position = "relative";
        setListeners(div, i, table);
      }
      function setListeners(div, i, table) {
        var pageX, curCol, curColWidth;
        div.addEventListener("mousedown", function (e) {
          curCol = e.target.parentElement;
          pageX = e.pageX;
          var padding = paddingDiff(curCol);
          curColWidth = curCol.offsetWidth - padding;
        });
        div.addEventListener("mouseover", function (e) {
          e.target.style.borderRight = "2px solid gray";
        });
        div.addEventListener("mouseout", function (e) {
          e.target.style.borderRight = "";
        });
        document.addEventListener("mousemove", function (e) {
          if (curCol) {
            var diffX = e.pageX - pageX;
            let rowDtl = table.getElementsByTagName("tr");
            for (var z = 1; z < rowDtl.length; z++) {
              let el = rowDtl[z].getElementsByTagName("td")[i];
              el.style.removeProperty("max-width");
            }
            curCol.style.setProperty(
              "min-width",
              curColWidth + diffX + "px",
              "important"
            );
          }
        });
        div.addEventListener("dblclick", function (e) {
          curCol = e.target.parentElement;
          curCol.style.setProperty("min-width", "max-content", "important");

          let rowDtl = table.getElementsByTagName("tr");
          for (var z = 1; z < rowDtl.length; z++) {
            let el = rowDtl[z].getElementsByTagName("td")[i];
            el.style.removeProperty("min-width");
            el.style.removeProperty("max-width");
            el.style.setProperty("max-width", "fit-content", "important");
            el.style.setProperty("min-width", "fit-content", "important");
          }

          curCol = undefined;
          pageX = undefined;
          curColWidth = undefined;
          if (e) return;
        });
        document.addEventListener("mouseup", function (e) {
          curCol = undefined;
          pageX = undefined;
          curColWidth = undefined;
          if (e) return;
        });
      }
      function createDiv(height) {
        var div = document.createElement("div");
        div.style.top = 0;
        div.style.right = 0;
        div.style.zIndex = 9999;
        div.style.width = "5px";
        div.style.position = "absolute";
        div.style.cursor = "col-resize";
        div.style.userSelect = "none";
        div.style.height = height + "px";
        return div;
      }
      function paddingDiff(col) {
        if (getStyleVal(col, "box-sizing") == "border-box") return 0;
        var padLeft = getStyleVal(col, "padding-left");
        var padRight = getStyleVal(col, "padding-right");
        return parseInt(padLeft) + parseInt(padRight);
      }
      function getStyleVal(elm, css) {
        return window.getComputedStyle(elm, null).getPropertyValue(css);
      }
    },
    checkIsEmpty(value) {
      if (typeof value === "string") {
        if (value.length == 0) return true;
        return false;
      }
      if (typeof value === "boolean") {
        return false;
      }
      return false;
    },
    seeDetail() {
      this.dialog = false;
      this.loadDataDetail();
    },
    handleRowClick(item) {
      this.curJob = item;
      this.dialog = true;
    },
    handleRowClick1(item) {
      this.curOper = item;
      this.complete = item.JobOper_SubComplete;
      this.dialog1 = true;
    },
    isBoolean(value) {
      return typeof value === "boolean";
    },
    convertDate(stringdate) {
      if (stringdate) {
        var dateObject = new Date(stringdate);
        var day = dateObject.getDate().toString().padStart(2, "0");
        var month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        var year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
      } else return "";
    },
    async getBAQ() {
      this.loading = true;
      let isSupplier =
        localStorage.getItem("isSupplierRole")?.toLowerCase?.() == "true";
      await callBAQ("DSP-MTN-GetSubcontractJobs", {
        VendorNum: !isSupplier ? "0" : this.getUserInfo.vendor_num + "",
      }).then((res) => {
        this.loading = false;
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          if (res.length > 0) {
            this.jobListData = [];
            this.operationListData = [];
            res.forEach((el) => {
              let job = this.jobListData.find(
                (i) => i.JobHead_JobNum && i.JobHead_JobNum == el.JobHead_JobNum
              );
              if (!job)
                this.jobListData.push({
                  JobHead_Company: el.JobHead_Company,
                  JobHead_JobNum: el.JobHead_JobNum,
                  JobHead_JobComplete: el.JobHead_JobComplete,
                  JobHead_JobEngineered: el.JobHead_JobEngineered,
                  JobHead_PartNum: el.JobHead_PartNum,
                  JobHead_PartDescription: el.JobHead_PartDescription,
                  JobHead_StartDate: el.JobHead_StartDate,
                  JobHead_DueDate: el.JobHead_DueDate,
                  JobHead_EquipID: el.JobHead_EquipID,
                });
              let oper = this.operationListData.find(
                (i) =>
                  i.JobHead_JobNum &&
                  i.JobHead_JobNum == el.JobHead_JobNum &&
                  i.JobOper_OprSeq &&
                  i.JobOper_OprSeq == el.JobOper_OprSeq
              );
              if (!oper)
                this.operationListData.push({
                  JobHead_JobNum: el.JobHead_JobNum,
                  JobOper_OprSeq: el.JobOper_OprSeq,
                  JobOper_OpComplete: el.JobOper_OpComplete,
                  JobOper_OpCode: el.JobOper_OpCode,
                  JobOper_OpDesc: el.JobOper_OpDesc,
                  JobOper_AssemblySeq: el.JobOper_AssemblySeq,
                  JobOper_PartNum: el.JobOper_PartNum,
                  JobOper_Description: el.JobOper_Description,
                  JobOper_IUM: el.JobOper_IUM,
                  JobOper_StartDate: el.JobOper_StartDate,
                  JobOper_DueDate: el.JobOper_DueDate,
                  JobOper_Sub_Complete_c: el.JobOper_Sub_Complete_c,
                });
            });
          }
        }
      });
      this.loadData();
    },
    loadData() {
      this.headerItems = [];
      if (this.jobListData && this.jobListData.length > 0) {
        this.jobListData.forEach((el) => {
          this.headerItems.push({
            JobHead_Company: el.JobHead_Company,
            JobHead_JobNum: el.JobHead_JobNum,
            JobHead_JobComplete: el.JobHead_JobComplete,
            JobHead_JobEngineered: el.JobHead_JobEngineered,
            JobHead_PartNum: el.JobHead_PartNum,
            JobHead_PartDescription: el.JobHead_PartDescription,
            JobHead_StartDate: this.convertDate(el.JobHead_StartDate),
            JobHead_DueDate: this.convertDate(el.JobHead_DueDate),
            JobHead_EquipID: el.JobHead_EquipID,
          });
        });
      }
      setTimeout(() => {
        this.setResize();
      }, 500);
    },
    loadDataDetail() {
      this.detailItems = [];
      if (this.operationListData && this.operationListData.length > 0) {
        this.operationListData.forEach((el) => {
          if (el.JobHead_JobNum == this.curJob.JobHead_JobNum)
            this.detailItems.push({
              JobHead_JobNum: el.JobHead_JobNum,
              JobOper_OprSeq: el.JobOper_OprSeq,
              JobOper_OpComplete: el.JobOper_OpComplete,
              JobOper_OpCode: el.JobOper_OpCode,
              JobOper_OpDesc: el.JobOper_OpDesc,
              JobOper_AssemblySeq: el.JobOper_AssemblySeq,
              JobOper_PartNum: el.JobOper_PartNum,
              JobOper_Description: el.JobOper_Description,
              JobOper_IUM: el.JobOper_IUM,
              JobOper_StartDate: this.convertDate(el.JobOper_StartDate),
              JobOper_DueDate: this.convertDate(el.JobOper_DueDate),
              JobOper_SubComplete: el.JobOper_Sub_Complete_c,
            });
        });
      }
      setTimeout(() => {
        window.scrollTo({
          top: document.getElementById("detail").offsetTop,
          left: 0,
          behavior: "smooth",
        });
        this.setResize();
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/standard.scss";
</style>
