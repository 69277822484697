import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => {
      if (!global.isWT) return import("@/components/pages/Shared/LogIn.vue");
      else return import("@/components/WT/LogIn.vue");
    },
    meta: {},
  },
  {
    path: "/login",
    name: "Login Path",
    component: () => {
      if (!global.isWT) return import("@/components/pages/Shared/LogIn.vue");
      else return import("@/components/WT/LogIn.vue");
    },
    meta: {},
  },
  {
    path: "/my-jobs",
    name: "Home",
    component: () => {
      return import("@/components/WT/MyJobs.vue");
    },
    meta: { layout: "home" },
  },
  {
    path: "/time-sheet",
    name: "Home",
    component: () => {
      return import("@/components/WT/TimeSheet.vue");
    },
    meta: { layout: "home" },
  },
  {
    path: "/history",
    name: "Home",
    component: () => {
      return import("@/components/WT/History.vue");
    },
    meta: { layout: "home" },
  },
  {
    path: "/dashboard",
    name: "Home",
    component: () => {
      return import("@/components/WT/Dashboard.vue");
    },
    meta: { layout: "home" },
  },
  {
    path: "/home",
    name: "Home",
    component: () => {
      return import("@/components/pages/Shared/Home.vue");
    },
    meta: { layout: "home" },
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: () => import("@/components/pages/Shared/ForgotPassword.vue"),
    meta: {},
  },
  {
    path: "/case-management",
    name: "Case Management",
    component: () =>
      import("@/components/pages/InternalMaintenance/CaseManagement.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/maintenance-job",
    name: "Maintenance Job",
    component: () =>
      import("@/components/pages/InternalMaintenance/EquipAndMtnjobs.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/maintenance-request-queue",
    name: "Maintenance Request Queue",
    component: () =>
      import("@/components/pages/InternalMaintenance/EquipAndMtnReqQueue.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/preventative-job",
    name: "Preventative Job",
    component: () =>
      import("@/components/pages/InternalMaintenance/EquipAndPreventjobs.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/equip-location",
    name: "Equip Location",
    component: () =>
      import("@/components/pages/InternalMaintenance/EquipLocation.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/equip-management",
    name: "Equip Management",
    component: () =>
      import("@/components/pages/InternalMaintenance/EquipManagement.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/job-selection",
    name: "Job Selection InternalMaintenance",
    component: () =>
      import("@/components/pages/InternalMaintenance/JobSelection.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/operation-timeline",
    name: "Operation Timeline",
    component: () =>
      import("@/components/pages/InternalMaintenance/OperationTimeline.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/scheduling-board",
    name: "Scheduling Board",
    component: () =>
      import("@/components/pages/InternalMaintenance/SchedulingBoard.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/billing",
    name: "Billing",
    component: () => import("@/components/pages/Supplier/Billing.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/case",
    name: "Case",
    component: () => import("@/components/pages/Supplier/Case.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/delivery",
    name: "Delivery",
    component: () => import("@/components/pages/Supplier/Delivery.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/job-calendar",
    name: "Job Calendar",
    component: () => import("@/components/pages/Supplier/JobCalendar.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/job-selection-supplier",
    name: "Job Selection Supplier",
    component: () => import("@/components/pages/Supplier/JobSelection.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/maintenance-request",
    name: "Maintenance Request",
    component: () =>
      import("@/components/pages/Supplier/MaintenanceRequest.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/managed-equipment",
    name: "Managed Equipment",
    component: () => import("@/components/pages/Supplier/ManagedEquipment.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/managed-inventory",
    name: "Managed Inventory",
    component: () => import("@/components/pages/Supplier/ManagedInv.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/my-subcontract-job",
    name: "Mysubcontract Job",
    component: () =>
      import("@/components/pages/Supplier/MysubcontractJobs.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/purchase-order",
    name: "Purchase Order",
    component: () => import("@/components/pages/Supplier/PurchaseOrder.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/meter-reading",
    name: "Meter Reading",
    component: () => import("@/components/pages/Shared/MeterReading.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/timesheet",
    name: "Timesheet",
    component: () => import("@/components/pages/Supplier/Timesheet.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/portal-help",
    name: "PortalHelp",
    component: () => import("@/components/pages/Shared/PortalHelp.vue"),
    meta: { layout: "home" },
  },
  {
    path: "/AgedCare-Form",
    name: "AgedCareSubmitForm",
    component: () => import("@/components/pages/Shared/AgedCareSubmitForm.vue"),
    meta: { layout: "home" },
  },
  {
    path: "*",
    name: "404",
    component: () => import("../components/pages/Shared/NotFoundPage.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
