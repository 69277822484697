<template>
  <v-card id="top-page" style="background-color: #5a6064">
    <v-card
      class="container_main_job"
      id="con-main"
      width="100%"
      min-height="100vh"
      height="wrap-content"
    >
      <div class="filter_bar">
        <v-radio-group
          v-model="radiogroup"
          v-show="windowWidth >= 1000"
          row
          style="width: 100%"
        >
          <template v-for="item in itemRadio" style="width: 100%">
            <v-list-tile :key="item.id" class="filter_item">
              <v-list-tile-action>
                <v-radio
                  :value="item.id"
                  :key="item.id"
                  color="black"
                ></v-radio>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-select
                  v-model="equips"
                  v-if="item.id == 1"
                  :items="equipsList"
                  class="autocomplete_select"
                  label="Select Equip"
                  :color="'black'"
                  :density="'comfortable'"
                  :bg-color="'black'"
                  style="color: black"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Select All"
                      class="selectall"
                      @click="toggleEquip"
                      >Select All ({{ equips.length }})
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }" color="black">
                    <v-chip v-if="index < 1">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="text-grey text-caption align-self-center"
                      >(+{{ equips.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
                <v-select
                  v-if="item.id == 2"
                  v-model="projects"
                  :items="projectsList"
                  class="autocomplete_select"
                  label="Select Project"
                  :color="'black'"
                  :density="'comfortable'"
                  :bg-color="'black'"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Select All"
                      class="selectall"
                      @click="toggleProject"
                      >Select All ({{ projects.length }})
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }" color="black">
                    <v-chip v-if="index < 1">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="text-grey text-caption align-self-center"
                      >(+{{ projects.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
                <v-select
                  v-if="item.id == 3"
                  v-model="resources"
                  :items="resourcesList"
                  class="autocomplete_select"
                  label="Select Resource"
                  :color="'black'"
                  :density="'comfortable'"
                  :bg-color="'black'"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Select All"
                      class="selectall"
                      @click="toggleResource"
                      >Select All ({{ resources.length }})
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }" color="black">
                    <v-chip v-if="index < 1">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="text-grey text-caption align-self-center"
                      >(+{{ resources.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
                <v-select
                  v-if="item.id == 4"
                  v-model="resourceGroups"
                  :items="resourceGroupsList"
                  class="autocomplete_select"
                  label="Select Resource Group"
                  color="black"
                  :density="'comfortable'"
                  :bg-color="'black'"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Select All"
                      class="selectall"
                      @click="toggleResourceGroup"
                      >Select All ({{ resourceGroups.length }})
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 1">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="text-grey text-caption align-self-center"
                      >(+{{ resourceGroups.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
              </v-list-tile-content>
            </v-list-tile>
          </template>
        </v-radio-group>
        <v-radio-group
          v-model="radiogroup"
          v-show="windowWidth < 1000"
          column
          style="width: 100%"
        >
          <template v-for="item in itemRadio" style="width: 100%">
            <v-list-tile :key="item.id" class="filter_item">
              <v-list-tile-action>
                <v-radio
                  :value="item.id"
                  :key="item.id"
                  color="black"
                ></v-radio>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-select
                  v-model="equips"
                  v-if="item.id == 1"
                  :items="equipsList"
                  class="autocomplete_select"
                  label="Select Equip"
                  :color="'black'"
                  :density="'comfortable'"
                  :bg-color="'black'"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Select All"
                      class="selectall"
                      @click="toggleEquip"
                      >Select All ({{ equips.length }})
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }" color="black">
                    <v-chip v-if="index < 1">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="text-grey text-caption align-self-center"
                      >(+{{ equips.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
                <v-select
                  v-if="item.id == 2"
                  v-model="projects"
                  :items="projectsList"
                  class="autocomplete_select"
                  label="Select Project"
                  :color="'black'"
                  :density="'comfortable'"
                  :bg-color="'black'"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Select All"
                      class="selectall"
                      @click="toggleProject"
                      >Select All ({{ projects.length }})
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }" color="black">
                    <v-chip v-if="index < 1">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="text-grey text-caption align-self-center"
                      >(+{{ projects.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
                <v-select
                  v-if="item.id == 3"
                  v-model="resources"
                  :items="resourcesList"
                  class="autocomplete_select"
                  label="Select Resource"
                  :color="'black'"
                  :density="'comfortable'"
                  :bg-color="'black'"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Select All"
                      class="selectall"
                      @click="toggleResource"
                      >Select All ({{ resources.length }})
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }" color="black">
                    <v-chip v-if="index < 1">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="text-grey text-caption align-self-center"
                      >(+{{ resources.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
                <v-select
                  v-if="item.id == 4"
                  v-model="resourceGroups"
                  :items="resourceGroupsList"
                  class="autocomplete_select"
                  label="Select Resource Group"
                  color="black"
                  :density="'comfortable'"
                  :bg-color="'black'"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Select All"
                      class="selectall"
                      @click="toggleResourceGroup"
                      >Select All ({{ resourceGroups.length }})
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 1">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="text-grey text-caption align-self-center"
                      >(+{{ resourceGroups.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
              </v-list-tile-content>
            </v-list-tile>
          </template>
        </v-radio-group>
      </div>
      <div class="joblist_table">
        <tableComponent :tableHeaders="headers" :tableItems="filteredItems">
        </tableComponent>
      </div>
      <div class="colorchange">
        <div class="group-btn">
          <v-btn
            @click="$router.push('/operation-timeline')"
            class="btn-redirect"
            size="x-large"
            style="font-size: 60%"
            >Go to Operation Timeline</v-btn
          >
          <v-btn
            @click="$router.push('/scheduling-board')"
            class="btn-redirect"
            size="x-large"
            style="font-size: 60%"
            >Go to Scheduling Board</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-card>
</template>
<script>
import tableComponent from "@/components/table/tableComponent.vue";
import { mapGetters } from "vuex";
import DecodeJWT from "@/utils/DecodeJWT";
export default {
  components: { tableComponent },
  mounted() {
    let token = localStorage.getItem("MTNToken");
    if (token == "" || !token) {
      this.$router.push("/login");
    } else {
      var info = DecodeJWT(token);
      if (info.exp <= new Date().getTime() / 1000) {
        this.$router.push("/login");
        localStorage.setItem("MTNToken", "");
      } else {
        this.getData();
        this.getSelectionType == "e"
          ? (this.equips = this.getSelection)
          : this.getSelectionType == "r"
          ? (this.resources = this.getSelection)
          : this.getSelectionType == "rg"
          ? (this.resourceGroups = this.getSelection)
          : (this.projects = this.getSelection);
      }
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      radiogroup: 1,
      equips: [],
      resources: [],
      resourceGroups: [],
      projects: [],
      equipsList: [],
      resourcesList: [],
      flag: "",
      resourceGroupsList: [],
      projectsList: [],
      itemRadio: [
        { Name: "equips", id: 1 },
        {
          Name: "projects",
          id: 2,
        },
        {
          Name: "resources",
          id: 3,
        },
        {
          Name: "resourcegroups",
          id: 4,
        },
      ],
      items: [],
      headers: [
        { text: "Job", value: "JobHead_JobNum", sortable: false },
        { text: "Start", value: "JobHead_StartDate", sortable: false },
        { text: "StartHour", value: "JobHead_StartHour", sortable: false },
        { text: "Date", value: "JobHead_DueDate", sortable: false },
        { text: "DueHour", value: "JobHead_DueHour", sortable: false },
        { text: "Opr", value: "JobOper_OprSeq", sortable: false },
        {
          text: "Operation Description",
          value: "JobOper_OpDesc",
          sortable: false,
        },
        { text: "Start Date", value: "JobOper_StartDate", sortable: false },
        { text: "StartHour", value: "JobOper_StartHour", sortable: false },
        { text: "Due Date", value: "JobOper_DueDate", sortable: false },
        { text: "DueHour", value: "JobOper_DueHour", sortable: false },
        { text: "LoadHour", value: "JobOper_LoadHour", sortable: false },
        {
          text: "Scheduling Code",
          value: "JobHead_SchedCode",
          sortable: false,
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters("Data", [
      "getEquipments",
      "getResources",
      "getResourceGroups",
      "getProjects",
      "getJobList",
      "getSelection",
      "getSelectionType",
    ]),
    ...mapGetters("User", ["getUserInfo"]),
    filteredItems() {
      if (this.equips.length > 0) {
        let list = [];
        this.equips.forEach((el) => {
          let a = el.split(": ");
          this.items.forEach((el1) => {
            if (el1.JobHead_EquipID == a[0]) list.push(el1);
          });
        });
        return list;
      }
      if (this.projects.length > 0) {
        let list = [];
        this.projects.forEach((el) => {
          let a = el.split(": ");
          this.items.forEach((el1) => {
            let b = el1.JobHead_ProjectID;
            this.flag = b;
            el1.JobHead_ProjectID = this.flag;
            if (el1.JobHead_ProjectID == a[0]) list.push(el1);
          });
        });
        return list;
      }
      if (this.resources.length > 0) {
        let list = [];
        this.resources.forEach((el) => {
          let a = el.split(": ");
          this.items.forEach((el1) => {
            if (el1.Resource_ResourceID == a[0]) list.push(el1);
          });
        });
        return list;
      }
      if (this.resourceGroups.length > 0) {
        let list = [];
        this.resourceGroups.forEach((el) => {
          let a = el.split(": ");
          this.items.forEach((el1) => {
            if (el1.JobOpDtl_ResourceGrpID == a[0]) list.push(el1);
          });
        });
        return list;
      } else return [];
    },
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    setResize() {
      var tables = document.getElementsByTagName("table");
      for (var i = 0; i < tables.length; i++) {
        this.resizableGrid(tables[i]);
      }
    },
    resizableGrid(table) {
      var row = table.getElementsByTagName("tr")[0],
        cols = row.getElementsByClassName("text-start");
      if (!cols) return;
      table.style.overflow = "hidden";
      for (var i = 0; i < cols.length; i++) {
        var div = createDiv(50);
        cols[i].appendChild(div);
        cols[i].style.position = "relative";
        setListeners(div, i, table);
      }
      function setListeners(div, i, table) {
        var pageX, curCol, curColWidth;
        div.addEventListener("mousedown", function (e) {
          curCol = e.target.parentElement;
          pageX = e.pageX;
          var padding = paddingDiff(curCol);
          curColWidth = curCol.offsetWidth - padding;
        });
        div.addEventListener("mouseover", function (e) {
          e.target.style.borderRight = "2px solid gray";
        });
        div.addEventListener("mouseout", function (e) {
          e.target.style.borderRight = "";
        });
        document.addEventListener("mousemove", function (e) {
          if (curCol) {
            var diffX = e.pageX - pageX;
            let rowDtl = table.getElementsByTagName("tr");
            for (var z = 1; z < rowDtl.length; z++) {
              let el = rowDtl[z].getElementsByTagName("td")[i];
              el.style.removeProperty("max-width");
            }
            curCol.style.setProperty(
              "min-width",
              curColWidth + diffX + "px",
              "important"
            );
          }
        });
        div.addEventListener("dblclick", function (e) {
          curCol = e.target.parentElement;
          curCol.style.setProperty("min-width", "max-content", "important");

          let rowDtl = table.getElementsByTagName("tr");
          for (var z = 1; z < rowDtl.length; z++) {
            let el = rowDtl[z].getElementsByTagName("td")[i];
            el.style.removeProperty("min-width");
            el.style.removeProperty("max-width");
            el.style.setProperty("max-width", "fit-content", "important");
            el.style.setProperty("min-width", "fit-content", "important");
          }

          curCol = undefined;
          pageX = undefined;
          curColWidth = undefined;
          if (e) return;
        });
        document.addEventListener("mouseup", function (e) {
          curCol = undefined;
          pageX = undefined;
          curColWidth = undefined;
          if (e) return;
        });
      }
      function createDiv(height) {
        var div = document.createElement("div");
        div.style.top = 0;
        div.style.right = 0;
        div.style.zIndex = 9999;
        div.style.width = "5px";
        div.style.position = "absolute";
        div.style.cursor = "col-resize";
        div.style.userSelect = "none";
        div.style.height = height + "px";
        return div;
      }
      function paddingDiff(col) {
        if (getStyleVal(col, "box-sizing") == "border-box") return 0;
        var padLeft = getStyleVal(col, "padding-left");
        var padRight = getStyleVal(col, "padding-right");
        return parseInt(padLeft) + parseInt(padRight);
      }
      function getStyleVal(elm, css) {
        return window.getComputedStyle(elm, null).getPropertyValue(css);
      }
    },
    unique(arr) {
      var newArr = [];
      newArr = arr.filter(function (item) {
        return newArr.includes(item) ? "" : newArr.push(item);
      });
      return newArr;
    },
    toggleEquip() {
      this.radiogroup = 1;
      if (this.equips.length == this.equipsList.length) {
        this.equips = [];
      } else {
        this.equips = [];
        this.equipsList.forEach((el) => {
          this.equips.push(el);
        });
      }
    },
    toggleResource() {
      this.radiogroup = 3;
      if (this.resources.length == this.resourcesList.length) {
        this.resources = [];
      } else {
        this.resources = [];
        this.resourcesList.forEach((el) => {
          this.resources.push(el);
        });
      }
    },
    toggleResourceGroup() {
      this.radiogroup = 4;
      if (this.resourceGroups.length == this.resourceGroupsList.length) {
        this.resourceGroups = [];
      } else {
        this.resourceGroups = [];
        this.resourceGroupsList.forEach((el) => {
          this.resourceGroups.push(el);
        });
      }
    },
    toggleProject() {
      this.radiogroup = 2;
      if (this.projects.length == this.projectsList.length) {
        this.projects = [];
      } else {
        this.projects = [];
        this.projectsList.forEach((el) => {
          this.projects.push(el);
        });
      }
    },
    convertDate(stringdate) {
      if (stringdate) {
        var dateObject = new Date(stringdate);
        var day = dateObject.getDate().toString().padStart(2, "0");
        var month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        var year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
      } else return "";
    },
    async getData() {
      await this.$store.dispatch("Data/getJobListFromBAQ", {
        Vendor: this.getUserInfo.vendor_num,
      });
      this.loadData();
    },
    loadData() {
      this.items = [];
      if (this.getJobList && this.getJobList.length > 0) {
        this.getJobList.forEach((el) => {
          this.items.push({
            Calculated_IsPreventJob: el.Calculated_IsPreventJob,
            Equip_SerialNum: el.Equip_SerialNum,
            JobHead_DueDate: this.convertDate(el.JobHead_DueDate),
            JobHead_DueHour: el.JobHead_DueHour,
            JobHead_EquipID: el.JobHead_EquipID,
            JobHead_JobNum: el.JobHead_JobNum,
            JobHead_JobType: el.JobHead_JobType,
            JobHead_PartDescription: el.JobHead_PartDescription,
            JobHead_PartNum: el.JobHead_PartNum,
            JobHead_PhaseID: el.JobHead_PhaseID,
            JobHead_Plant: el.JobHead_Plant,
            JobHead_ProjectID: el.JobHead_ProjectID,
            JobHead_ReqDueDate: this.convertDate(el.JobHead_ReqDueDate),
            JobHead_SchedCode: el.JobHead_SchedCode,
            JobHead_SchedLocked: el.JobHead_SchedLocked,
            JobHead_SchedPriority: el.JobHead_SchedPriority,
            JobHead_SchedStatus: el.JobHead_SchedStatus,
            JobHead_StartDate: this.convertDate(el.JobHead_StartDate),
            JobHead_StartHour: el.JobHead_StartHour,
            JobOper_DueDate: this.convertDate(el.JobOper_DueDate),
            JobOper_DueHour: el.JobOper_DueHour,
            JobOper_LoadHour: el.JobOper_LoadHour,
            JobOper_OpDesc: el.JobOper_OpDesc,
            JobOper_OprSeq: el.JobOper_OprSeq,
            JobOper_StartDate: this.convertDate(el.JobOper_StartDate),
            JobOper_StartHour: el.JobOper_StartHour,
            Resource_Description: el.Resource_Description,
            Resource_ResourceID: el.Resource_ResourceID,
            JobOpDtl_ResourceGrpID: el.JobOpDtl_ResourceGrpID,
            imgID: el.Equip_ImageID_c,
          });
        });
      }
      setTimeout(() => {
        this.setResize();
      }, 500);
    },
  },
  watch: {
    getEquipments() {
      this.equipsList = [];
      this.getEquipments.forEach((el) => {
        this.equipsList.push(el.equipID + ": " + el.equipName);
      });
    },
    getResources() {
      this.resourcesList = [];
      this.getResources.forEach((el) => {
        this.resourcesList.push(el.resourceID + ": " + el.resourceName);
      });
    },
    getProjects() {
      this.projectsList = [];
      this.getProjects.forEach((el) => {
        this.projectsList.push(el.projectID + ": " + el.projectName);
      });
    },
    getResourceGroups() {
      this.resourceGroupsList = [];
      this.getResourceGroups.forEach((el) => {
        this.resourceGroupsList.push(el.groupID + ": " + el.groupName);
      });
    },
    getJobList() {
      this.loadData();
    },
    filteredItems() {
      let sel = [];
      let type = "";
      setTimeout(() => {
        this.setResize();
      }, 50);
      if (this.equips.length > 0) {
        sel = this.equips;
        type = "e";
      } else if (this.resources.length > 0) {
        sel = this.resources;
        type = "r";
      } else if (this.resourceGroups.length > 0) {
        sel = this.resourceGroups;
        type = "rg";
      } else {
        sel = this.projects;
        type = "p";
      }
      this.$store.dispatch("Data/changeJobSelection", {
        list: this.filteredItems,
        selection: sel,
        type,
      });
    },
    radiogroup() {
      switch (this.radiogroup) {
        case 1:
          this.resources = [];
          this.resourceGroups = [];
          this.projects = [];
          break;
        case 2:
          this.equips = [];
          this.resources = [];
          this.resourceGroups = [];
          break;
        case 3:
          this.equips = [];
          this.resourceGroups = [];
          this.projects = [];
          break;
        case 4:
          this.equips = [];
          this.resources = [];
          this.projects = [];
          break;
      }
    },
    equips() {
      if (this.equips.length > 0) {
        this.flag = "T";
        this.radiogroup = 1;
        this.resources = [];
        this.resourceGroups = [];
        this.projects = [];
      }
    },
    resources() {
      if (this.resources.length > 0) {
        this.radiogroup = 3;
        this.equips = [];
        this.resourceGroups = [];
        this.projects = [];
      }
    },
    resourceGroups() {
      if (this.resourceGroups.length > 0) {
        this.radiogroup = 4;
        this.equips = [];
        this.resources = [];
        this.projects = [];
      }
    },
    projects() {
      if (this.projects.length > 0) {
        this.radiogroup = 2;
        this.equips = [];
        this.resources = [];
        this.resourceGroups = [];
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/css/standard.scss";

.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: blue !important;
}

.filter_item {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
}

.radio-horizontal
  > .v-input__control
  > .v-input__slot
  > .v-input--radio-group__input {
  display: flex;
  flex-direction: row;
}

.container_main_job {
  display: flex;
  flex-wrap: wrap;
  height: auto;

  .filter_bar {
    width: 100%;
    height: 15vh;
    display: flex;
    flex-wrap: wrap;

    .filter_bar_choice {
      width: 100%;
      height: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      margin: 0;
      flex-direction: row;
      margin-bottom: 0;
      margin-right: 0;
      padding: 0;
    }
  }

  .joblist_table {
    width: 80%;
    height: fit-content;
  }

  .colorchange {
    width: 20%;
    height: 85vh;
  }

  .table {
    height: auto;
  }

  .autocomplete_select {
    color: black !important;
  }

  .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 0px;
  }

  .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 0px;
  }

  .v-input--radio-group--column .v-input--radio-group__input {
    flex-direction: row;
  }

  .v-input--radio-group__input {
    width: 25%;
  }

  .selectall {
    color: red;
  }
}

.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.group-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .btn-redirect {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
    word-break: break-all;
    margin-bottom: 10px;
  }
}
.v-chip__content {
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;
  }
}

@media (max-width: 1000px) {
  .v-chip__content {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100vw;
    }
  }
  .container_main_job {
    &:nth-child(3) {
      order: 2;
    }
    flex-wrap: wrap !important;
  }
  .joblist_table {
    order: 3;
    width: 100% !important;
  }

  .filter_bar {
    height: 100% !important;
    min-height: 80vh !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .filter_item {
      width: 100% !important;
    }

    .autocomplete_select {
      width: 100% !important;
    }
  }

  .colorchange {
    order: 2;
    width: 100% !important;
    min-height: 20vh !important;
    height: 20vh !important;
  }
}

.v-application .primary--text {
  color: #0f121a !important;
  caret-color: #e5eaf5 !important;
}

.v-list .v-list-item--active {
  background-color: green !important;
}

::v-deep .v-list-item__content {
  color: black;
}

::v-deep .mdi-checkbox-marked::before {
  color: black;
}

::v-deep .v-list-item--link:before {
  background-color: white;
}
</style>
