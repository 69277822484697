import { callBAQ } from "../Method/index";
export default {
  async getInfo(state) {
    console.trace(localStorage.getItem("cust_uid"));
    await callBAQ("DSP-MTN-GetSupplierInfo", {
      PerconID: localStorage.getItem("cust_uid") + "",
    }).then((res) => {
      if (res && res.message == "error") {
        alert("res" + res.error);
      } else {
        if (res.length > 0) {
          state.company = res[0].PerCon_Company;
          state.uid = res[0].PerCon_PerConID;
          state.name = res[0].PerCon_Name;
          state.phone = res[0].PerCon_PhoneNum;
          state.email = res[0].PerCon_EMailAddress;
          state.cell_phone = res[0].PerCon_CellPhoneNum;
          state.vendor_id = res[0].Vendor_VendorID;
          state.vendor_name = res[0].Vendor_Name;
          state.vendor_num = res[0].Vendor_VendorNum;
          state.isUseCase = res[0].Company_PKV_isUseCaseForm_c;
          localStorage.setItem("userinfo", JSON.stringify(state));
        }
      }
    });
  },
  logout(state) {
    state.token = "";
    state.vendor_num = "";
    localStorage.setItem("MTNToken", "");
  },
  updateInfo(state, payload) {
    state.email = payload.user.email;
    state.cust_id = payload.user.cust_id;
    state.cust_num = payload.user.cust_num;
    state.cell_phone = payload.user.cell_phone;
    state.phone = payload.user.phone;
    state.name = payload.user.name;
    state.uid = payload.user.uid;
    localStorage.setItem("cust_num", state.cust_num);
    localStorage.setItem("cust_uid", state.uid);
    setTimeout(() => {
      localStorage.setItem("userinfo", JSON.stringify(state));
    }, 200);
  },
  loadUserInfo(state) {
    if (localStorage.getItem("userinfo"))
      Object.assign(state, JSON.parse(localStorage.getItem("userinfo")));
  },
  async DSPGetCompanyInfo(state) {
    await callBAQ("DSPGetCompanyInfo", {
      CustNum: localStorage.getItem("cust_num") + "",
      PerconID: localStorage.getItem("cust_uid") + "",
    }).then((res) => {
      if (res && res.message == "error") {
        alert("res" + res.error);
      } else {
        if (res.length > 0) {
          state.company = res[0].Customer_Company;
          state.cust_name = res[0].Customer_Name;
          state.cust_id = res[0].Customer_CustID;
          state.cust_num = res[0].Customer_CustNum;
          state.name = res[0].PerCon_Name;
          state.email = res[0].PerCon_EMailAddress;
          state.phone = res[0].PerCon_PhoneNum;
          state.vendor_id = res[0].Vendor_VendorID;
          state.vendor_num = res[0].VendCnt_VendorNum;
          state.vendor_name = res[0].Vendor_Name;
          // state.permision.case = res[0].CustCnt_PortalCases_c;
          // state.permision.call = res[0].CustCnt_PortalServiceCalls_c;
          // state.permision.job = res[0].CustCnt_PortalServiceJobs_c;
          // state.permision.equipment = res[0].CustCnt_PortalEquipments_c;
          // state.permision.invoice = res[0].CustCnt_PortalInvoices_c;
          // state.permision.contract = res[0].CustCnt_PortalServiceContracts_c;
          // state.permision.order = res[0].CustCnt_PortalOrders_c;
          // state.permision.shipment = res[0].CustCnt_PortShipments_c;
          // state.permision.quote = res[0].CustCnt_PortalQuotes_c;
          // state.permision.rma = res[0].CustCnt_PortalRMA_c;
        }
      }
    });
  },
};
