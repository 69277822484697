<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import layoutBlank from "./components/layouts/Blank.vue";
import layoutMenu from "./components/layouts/LeftMenu.vue";
import layoutWashtech from "@/components/layouts/WTLeftMenu.vue";

export default {
  name: "App",
  components: {
    layoutBlank,
    layoutMenu,
    layoutWashtech,
  },
  data: () => ({
    layout: "layout-blank",
  }),
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  watch: {
    $route() {
      if (this.$route.meta.layout !== "home") {
        this.layout = "layout-blank";
      } else {
        if (global.isWT) this.layout = "layout-washtech";
        else this.layout = "layout-menu";
      }
    },
  },
};
</script>
