<template>
  <div class="container" id="top-page">
    <v-expansion-panels multiple v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="Header"
          >Purchase Order List</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div
            v-if="loading"
            style="position: absolute; width: 95%; height: 90%"
          >
            <div
              style="
                display: flex;
                flex: 1 1 auto;
                justify-content: center;
                align-items: center;
                height: 100%;
              "
            >
              <Loading :color="'#006680'" :secondaryColor="'#006680'"></Loading>
            </div>
          </div>
          <h4 style="font-weight: bold">Search:</h4>
          <input
            class="form-control border input-search"
            id="myInput"
            type="text"
            v-model="search1"
          />
          <br />
          <v-data-table
            hide-default-footer
            :items-per-page="5"
            :headers="poListHeader"
            :items="headerItemsFilterDate"
            class="elevation-1 table-header"
            @click:row="handleRowClick"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              />
              <v-dialog v-model="dialog" max-width="750px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Purchase Order Information</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div v-for="(header, idx) in poListHeader" :key="idx">
                          <v-col cols="auto" sm="auto" md="auto">
                            <v-text-field
                              v-if="header.text == 'PromiseDate'"
                              :label="header.text"
                              type="date"
                              v-model="curPO[header.value]"
                              class="input_date"
                            />
                            <v-text-field
                              color="black"
                              v-else-if="checkIsEmpty(curPO[header.value])"
                              v-model="emptyItem"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                            <v-text-field
                              color="black"
                              v-else
                              v-model="curPO[header.value]"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                          </v-col>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="close">
                      OK
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="seeDetail">
                      See Order Detail List
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template
              v-for="header in poListHeader"
              v-slot:[`item.${header.value}`]="{ item }"
            >
              <template v-if="isBoolean(item[header.value])">
                <div class="checkbox_container" v-bind:key="item[header.value]">
                  <v-checkbox
                    v-bind:key="item[header.value]"
                    v-model="item[header.value]"
                    :true-value="true"
                    :false-value="false"
                    light
                    disabled
                    color="black"
                  ></v-checkbox>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel id="detail">
        <v-expansion-panel-header class="Header"
          >Purchase Order Line List</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div
            v-if="loadingDtl"
            style="position: absolute; width: 95%; height: 90%"
          >
            <div
              style="
                display: flex;
                flex: 1 1 auto;
                justify-content: center;
                align-items: center;
                height: 100%;
              "
            >
              <Loading :color="'#006680'" :secondaryColor="'#006680'"></Loading>
            </div>
          </div>
          <h4 style="font-weight: bold">Search:</h4>
          <input
            class="form-control border input-search"
            id="myInput"
            type="text"
            v-model="search2"
          />
          <br />
          <v-data-table
            hide-default-footer
            :items-per-page="5"
            :headers="orderDetailHeader"
            :items="detailItemsFilterDate"
            class="elevation-1 table-header"
            @click:row="handleRowClick1"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              />
              <v-dialog v-model="dialog1" max-width="750px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Purchase Order Line Information</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div
                          v-for="(header, idx) in orderDetailHeader"
                          :key="idx"
                        >
                          <v-col cols="auto" sm="auto" md="auto">
                            <v-text-field
                              color="black"
                              v-if="checkIsEmpty(curLine[header.value])"
                              v-model="emptyItem"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                            <v-text-field
                              color="black"
                              v-else
                              v-model="curLine[header.value]"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                          </v-col>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="close">
                      OK
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="seeDetail1">
                      See Order Release List
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template
              v-for="header in orderDetailHeader"
              v-slot:[`item.${header.value}`]="{ item }"
            >
              <template v-if="isBoolean(item[header.value])">
                <div class="checkbox_container" v-bind:key="item[header.value]">
                  <v-checkbox
                    v-bind:key="item[header.value]"
                    v-model="item[header.value]"
                    :true-value="true"
                    :false-value="false"
                    light
                    disabled
                    color="black"
                  ></v-checkbox>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel id="release">
        <v-expansion-panel-header class="Header"
          >Purchase Order Release List</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div
            v-if="loadingDtl2"
            style="position: absolute; width: 95%; height: 90%"
          >
            <div
              style="
                display: flex;
                flex: 1 1 auto;
                justify-content: center;
                align-items: center;
                height: 100%;
              "
            >
              <Loading :color="'#006680'" :secondaryColor="'#006680'"></Loading>
            </div>
          </div>
          <h4 style="font-weight: bold">Search:</h4>
          <input
            class="form-control border input-search"
            id="myInput"
            type="text"
            v-model="search3"
          />
          <br />
          <v-data-table
            hide-default-footer
            :items-per-page="5"
            :headers="relHeader"
            :items="relItemsFilterDate"
            class="elevation-1 table-header"
            @click:row="handleRowClick2"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              />
              <v-dialog v-model="dialog2" max-width="750px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5"
                      >Purchase Order Release Information</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div v-for="(header, idx) in relHeader" :key="idx">
                          <v-col cols="auto" sm="auto" md="auto">
                            <v-text-field
                              v-if="header.text == 'PromiseDt'"
                              :label="header.text"
                              type="date"
                              v-model="promisedt"
                              class="input_date"
                            />
                            <v-text-field
                              color="black"
                              v-else-if="checkIsEmpty(curRel[header.value])"
                              v-model="emptyItem"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                            <v-text-field
                              color="black"
                              v-else
                              v-model="curRel[header.value]"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                          </v-col>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="close">
                      OK
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template
              v-for="header in relHeader"
              v-slot:[`item.${header.value}`]="{ item }"
            >
              <template v-if="isBoolean(item[header.value])">
                <div class="checkbox_container" v-bind:key="item[header.value]">
                  <v-checkbox
                    v-bind:key="item[header.value]"
                    v-model="item[header.value]"
                    :true-value="true"
                    :false-value="false"
                    light
                    disabled
                    color="black"
                  ></v-checkbox>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <tableComponent
      style="display: none"
      :tableHeaders="[]"
      :tableItems="[]"
    ></tableComponent>
  </div>
</template>

<script>
import tableComponent from "@/components/table/tableComponent.vue";
import { mapGetters } from "vuex";
import DecodeJWT from "@/utils/DecodeJWT";
import Loading from "../../loading/Loading.vue";
import { callBAQ, callMethodFunc } from "@/store/Method/index";

export default {
  components: { tableComponent, Loading },
  mounted() {
    let token = localStorage.getItem("MTNToken");
    if (token == "" || !token) {
      this.$router.push("/login");
    } else {
      var info = DecodeJWT(token);
      if (info.exp <= new Date().getTime() / 1000) {
        this.$router.push("/login");
      } else {
        window.scrollTo({
          top: document.getElementById("top-page").offsetTop,
          left: 0,
          behavior: "smooth",
        });
        this.panel = Array.from(Array(3).keys());
        this.getBAQOrder();
        const today = new Date();
        const fromday = new Date();
        fromday.setMonth(fromday.getMonth() - 3);
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, "0");
        let day = today.getDate().toString().padStart(2, "0");
        this.todate1 = this.todate2 = `${year}-${month}-${day}`;
        year = fromday.getFullYear();
        month = (fromday.getMonth() + 1).toString().padStart(2, "0");
        day = fromday.getDate().toString().padStart(2, "0");
        this.fromdate1 = this.fromdate2 = `${year}-${month}-${day}`;
      }
    }
  },
  data() {
    return {
      headersNotfound: [{ text: "", value: "nf" }],
      itemNotfound: [{ nf: "Data not found!" }],
      poListHeader: [],
      orderDetailHeader: [],
      relHeader: [],
      headerItems: [],
      promisedt: "2023-10-07",
      detailItems: [],
      relItems: [],
      panel: [],
      curPO: {},
      curLine: {},
      curRel: {},
      dialog: false,
      dialog1: false,
      dialog2: false,
      emptyItem: " ",
      search1: "",
      search2: "",
      search3: "",
      fromdate1: "",
      todate1: "",
      fromdate2: "",
      todate2: "",
      loading: false,
      loadingDtl: false,
      loadingDtl2: false,
    };
  },
  watch: {},
  computed: {
    ...mapGetters("User", ["getUserInfo"]),
    headerItemsFilter() {
      const filterKey = (this.search1 || "").toLowerCase();
      if (this.headerItems == null || filterKey === "") return this.headerItems;
      return this.headerItems.filter((i) => {
        return (
          i.POHeader_PONum.toString().toLowerCase().indexOf(filterKey) > -1
        );
      });
    },
    headerItemsFilterDate() {
      if (this.headerItemsFilter.length > 0) {
        // let from = new Date(this.fromdate1);
        // let to = new Date(this.todate1)
        // return this.headerItemsFilter.filter((i) => {
        //   let day = i.JobHead_DueDate.split('/')[0];
        //   let month = i.JobHead_DueDate.split('/')[1];
        //   let year = i.JobHead_DueDate.split('/')[2];
        //   let date = new Date(year, month-1, day);
        //   return date <= to && from <= date;
        // });
        return this.headerItemsFilter;
      } else {
        return this.headerItemsFilter;
      }
    },
    detailItemsFilter() {
      const filterKey = (this.search2 || "").toLowerCase();
      if (this.detailItems == null || filterKey === "") return this.detailItems;
      return this.detailItems.filter((i) => {
        return (
          i.PODetail_POLine.toString().toLowerCase().indexOf(filterKey) > -1
        );
      });
    },
    detailItemsFilterDate() {
      if (this.detailItemsFilter.length > 0) {
        // let from = new Date(this.fromdate2);
        // let to = new Date(this.todate2);
        // return this.detailItemsFilter.filter((i) => {
        //   let day = i.HDCase_CreatedDate.split("/")[0];
        //   let month = i.HDCase_CreatedDate.split("/")[1];
        //   let year = i.HDCase_CreatedDate.split("/")[2];
        //   let date = new Date(year, month - 1, day);
        //   return date <= to && from <= date;
        // });
        return this.detailItemsFilter;
      } else {
        return this.detailItemsFilter;
      }
    },
    relItemsFilter() {
      const filterKey = (this.search3 || "").toLowerCase();
      if (this.relItems == null || filterKey === "") return this.relItems;
      return this.relItems.filter((i) => {
        return (
          i.PORel_PORelNum.toString().toLowerCase().indexOf(filterKey) > -1
        );
      });
    },
    relItemsFilterDate() {
      if (this.relItemsFilter.length > 0) {
        // let from = new Date(this.fromdate2);
        // let to = new Date(this.todate2);
        // return this.relItemsFilter.filter((i) => {
        //   let day = i.HDCase_CreatedDate.split("/")[0];
        //   let month = i.HDCase_CreatedDate.split("/")[1];
        //   let year = i.HDCase_CreatedDate.split("/")[2];
        //   let date = new Date(year, month - 1, day);
        //   return date <= to && from <= date;
        // });
        return this.relItemsFilter;
      } else {
        return this.relItemsFilter;
      }
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.dialog1 = false;
      this.dialog2 = false;
    },
    checkIsEmpty(value) {
      if (typeof value === "string") {
        if (value.length == 0) return true;
        return false;
      }
      if (typeof value === "boolean") {
        return false;
      }
      return false;
    },
    seeDetail() {
      this.dialog = false;
      this.getBAQOrderDetail(this.curPO);
    },
    seeDetail1() {
      this.dialog1 = false;
      this.getBAQOrderRel(this.curLine);
    },
    async save() {
      let bo = "Erp.BO.PO";
      let method1 = "GetByID";
      let body1 = {
        poNum: this.curRel.PORel_PONum,
      };
      await callMethodFunc(bo, method1, body1).then(async (res1) => {
        if (res1 && res1.message == "error") {
          alert("res1" + res1.error);
        } else {
          let returnObj = JSON.parse(JSON.parse(res1.data).data).returnObj;
          let method2 = "Update";
          let date = new Date(this.promisedt);
          let day = date.getDate().toString().padStart(2, "0"),
            month = (date.getMonth() + 1).toString().padStart(2, "0"),
            year = date.getFullYear().toString();
          let body2 = {
            ds: {
              ...returnObj,
              PORel: [
                {
                  ...returnObj.PORel.filter((i) => {
                    return (
                      i.PONum == this.curRel.PORel_PONum &&
                      i.POLine == this.curRel.PORel_POLine &&
                      i.PORelNum == this.curRel.PORel_PORelNum
                    );
                  })[0],
                  RowMod: "U",
                  PromiseDt: `${year}-${month}-${day}T00:00:00+07:00`,
                },
              ],
            },
          };
          await callMethodFunc(bo, method2, body2).then(async (res2) => {
            if (res2 && res2.message == "error") {
              alert("res2" + res2.error);
            } else {
              this.close();
              await this.getBAQOrderRel(this.curLine);
            }
          });
        }
      });
    },
    handleRowClick(item) {
      this.curPO = item;
      this.dialog = true;
    },
    handleRowClick1(item) {
      this.curLine = item;
      this.dialog1 = true;
    },
    handleRowClick2(item) {
      this.curRel = item;
      let date = new Date(item.PORel_PromiseDt);
      this.promisedt = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.dialog2 = true;
    },
    isBoolean(value) {
      return typeof value === "boolean";
    },
    convertDate(stringdate) {
      if (stringdate) {
        var dateObject = new Date(stringdate);
        var day = dateObject.getDate().toString().padStart(2, "0");
        var month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        var year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
      } else return "";
    },
    convertDate1(stringdate) {
      if (stringdate) {
        var dateObject = new Date(stringdate);
        var day = dateObject.getDate().toString().padStart(2, "0");
        var month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        var year = dateObject.getFullYear();
        return `${year}-${month}-${day}`;
      } else return "";
    },
    loadData(data) {
      let item = [],
        header = [];
      for (var title in data[0]) {
        header.push({
          text: title.split("_")[1],
          value: title,
          sortable: false,
        });
      }
      if (data && data.length > 0) {
        data.forEach((el) => {
          let i = {};
          for (var e in el) {
            if (e.includes("Date")) i[e] = this.convertDate(el[e]);
            else i[e] = el[e];
          }
          item.push(i);
        });
      }
      return { h: header, i: item };
    },
    setResize() {
      var tables = document.getElementsByTagName("table");
      for (var i = 0; i < tables.length; i++) {
        this.resizableGrid(tables[i]);
      }
    },
    resizableGrid(table) {
      var row = table.getElementsByTagName("tr")[0],
        cols = row.getElementsByClassName("text-start");
      if (!cols) return;
      table.style.overflow = "hidden";
      for (var i = 0; i < cols.length; i++) {
        var div = createDiv(50);
        cols[i].appendChild(div);
        cols[i].style.position = "relative";
        setListeners(div, i, table);
      }
      function setListeners(div, i, table) {
        var pageX, curCol, curColWidth;
        div.addEventListener("mousedown", function (e) {
          curCol = e.target.parentElement;
          pageX = e.pageX;
          var padding = paddingDiff(curCol);
          curColWidth = curCol.offsetWidth - padding;
        });
        div.addEventListener("mouseover", function (e) {
          e.target.style.borderRight = "2px solid gray";
        });
        div.addEventListener("mouseout", function (e) {
          e.target.style.borderRight = "";
        });
        document.addEventListener("mousemove", function (e) {
          if (curCol) {
            var diffX = e.pageX - pageX;
            let rowDtl = table.getElementsByTagName("tr");
            for (var z = 1; z < rowDtl.length; z++) {
              let el = rowDtl[z].getElementsByTagName("td")[i];
              el.style.removeProperty("max-width");
            }
            curCol.style.setProperty(
              "min-width",
              curColWidth + diffX + "px",
              "important"
            );
          }
        });
        div.addEventListener("dblclick", function (e) {
          curCol = e.target.parentElement;
          curCol.style.setProperty("min-width", "max-content", "important");

          let rowDtl = table.getElementsByTagName("tr");
          for (var z = 1; z < rowDtl.length; z++) {
            let el = rowDtl[z].getElementsByTagName("td")[i];
            el.style.removeProperty("min-width");
            el.style.removeProperty("max-width");
            el.style.setProperty("max-width", "fit-content", "important");
            el.style.setProperty("min-width", "fit-content", "important");
          }

          curCol = undefined;
          pageX = undefined;
          curColWidth = undefined;
          if (e) return;
        });
        document.addEventListener("mouseup", function (e) {
          curCol = undefined;
          pageX = undefined;
          curColWidth = undefined;
          if (e) return;
        });
      }
      function createDiv(height) {
        var div = document.createElement("div");
        div.style.top = 0;
        div.style.right = 0;
        div.style.zIndex = 9999;
        div.style.width = "5px";
        div.style.position = "absolute";
        div.style.cursor = "col-resize";
        div.style.userSelect = "none";
        div.style.height = height + "px";
        return div;
      }
      function paddingDiff(col) {
        if (getStyleVal(col, "box-sizing") == "border-box") return 0;
        var padLeft = getStyleVal(col, "padding-left");
        var padRight = getStyleVal(col, "padding-right");
        return parseInt(padLeft) + parseInt(padRight);
      }
      function getStyleVal(elm, css) {
        return window.getComputedStyle(elm, null).getPropertyValue(css);
      }
    },
    async getBAQOrder() {
      this.loading = true;
      let isSupplier =
        localStorage.getItem("isSupplierRole")?.toLowerCase?.() == "true";
      await callBAQ("DSP-MTN-GetPOHeader", {
        VendorNum: !isSupplier ? "0" : this.getUserInfo.vendor_num + "",
      }).then((res) => {
        this.loading = false;
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          let returnData = this.loadData(res);
          (this.poListHeader = returnData.h), (this.headerItems = returnData.i);
          setTimeout(() => {
            this.setResize();
          }, 500);
        }
      });
    },
    async getBAQOrderDetail(item) {
      this.loadingDtl = true;
      await callBAQ("DSP-MTN-GetPODetail", {
        PONum: item.POHeader_PONum + "",
      }).then((res) => {
        this.loadingDtl = false;
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          let returnData = this.loadData(res);
          (this.orderDetailHeader = returnData.h),
            (this.detailItems = returnData.i);
          setTimeout(() => {
            window.scrollTo({
              top: document.getElementById("detail").offsetTop,
              left: 0,
              behavior: "smooth",
            });
            this.setResize();
          }, 500);
        }
      });
    },
    async getBAQOrderRel(item) {
      this.loadingDtl2 = true;
      await callBAQ("DSP-MTN-GetPORel", {
        PONum: item.PODetail_PONUM + "",
        POLine: item.PODetail_POLine + "",
      }).then((res) => {
        this.loadingDtl2 = false;
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          let returnData = this.loadData(res);
          (this.relHeader = returnData.h), (this.relItems = returnData.i);
          for (let el of this.relItems) {
            let a = {
              ...el,
              PORel_PromiseDt: this.convertDate(el.PORel_PromiseDt),
            };
            el = a;
          }
          setTimeout(() => {
            window.scrollTo({
              top: document.getElementById("release").offsetTop,
              left: 0,
              behavior: "smooth",
            });
            this.setResize();
          }, 500);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/standard.scss";
</style>
