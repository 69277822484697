import baseURL from "../../utils/API_URL";
import axios from "axios";
async function callMethodFunc(bo, method, body) {
  if (!localStorage.getItem("MTNToken")) return Promise.reject();
  const url = baseURL + "api/method";
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("MTNToken"),
    Accept: "*/*",
    "Content-Type": "application/json",
  };
  const body1 = {
    IsOData: false,
    BO: bo,
    Method: method,
    HttpMethod: method,
    Company: "EPIC06",
    Plant: "MfgSys",
    Data: body,
    BpmData: {
      Context: {},
    },
  };
  try {
    const response = await axios.post(url, body1, { headers });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.resolve({ message: "error", error });
  }
}
async function methodCreateRequest(user) {
  if (!localStorage.getItem("MTNToken")) return Promise.reject();
  const url = baseURL + "api/create-request";
  const body = {
    name: user.name,
    email: user.email,
    phone: user.phone,
    call_phone: user.phone,
    company_name: user.company,
    company_address: user.company_address,
    title: "Maintenance Request",
    equip: user.equipment,
    serial: user.equipment_serial,
    message: user.comment,
  };
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("MTNToken"),
    Accept: "*/*",
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, body, { headers });
    return Promise.resolve(response.data.Data.HDCaseNum);
  } catch (error) {
    return Promise.resolve({ message: "error", error });
  }
}
async function callBAQ(baq, param) {
  if (!localStorage.getItem("MTNToken")) return Promise.reject();
  const url = baseURL + "api/call-baq";
  const body = {
    CompanyID: "epic06",
    PlantID: "MfgSys",
    Company: "epic06",
    Plant: "MfgSys",
    QueryID: baq,
    IsTest: false,
    Mode: 0,
    UseNewDataFormat: true,
    Take: 0,
    Skip: 0,
    SortBy: null,
    SysRevID: 0,
    Parameters: param,
  };
  const headers = {
    Accept: "*/*",
    Authorization: "Bearer " + localStorage.getItem("MTNToken"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, body, { headers });
    return Promise.resolve(JSON.parse(response.data.DataRaw));
  } catch (error) {
    return Promise.resolve({ message: "error", error });
  }
}
async function callApiLogin(email, password) {
  let url = "";
  if (email.includes("@")) {
    localStorage.setItem("isSupplierRole", true);
    url = baseURL + "api/login";
  } else {
    localStorage.setItem("isSupplierRole", false);
    url = baseURL + "api/login-epicor";
  }
  const body = {
    username: email,
    password: password,
    is_test: true,
  };
  const headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, body, { headers });
    return Promise.resolve(response.data.token);
  } catch (error) {
    return Promise.resolve({ message: "error", error });
  }
}
async function callApiRegister(newUser) {
  const url = baseURL + "api/create-request";
  const body = {
    name: newUser.userName,
    email: newUser.userEmail,
    phone: newUser.userPhone,
    call_phone: newUser.userPhone,
    company_name: newUser.company ? newUser.company : "epic06",
    company_address: newUser.company_address
      ? newUser.company_address
      : "123 abc xyz",
    equip: newUser.equipment ? newUser.equipment : "123",
    serial: newUser.equipment_serial ? newUser.equipment_serial : "123",
    title: "Register",
    message: newUser.comment ? newUser.comment : "Register",
  };
  const headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, body, { headers });
    return Promise.resolve(response.data.Data.HDCaseNum);
  } catch (error) {
    return Promise.resolve({ message: "error", error });
  }
}
async function callApiChangePassword(old, newp, token) {
  const url = baseURL + "api/changepass";
  const body = {
    old_pass: old,
    new_pass: newp,
  };
  const headers = {
    Authorization: "Bearer " + token,
    Accept: "*/*",
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, body, { headers });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.resolve({ message: "error", error });
  }
}

export {
  callMethodFunc,
  methodCreateRequest,
  callBAQ,
  callApiLogin,
  callApiRegister,
  callApiChangePassword,
};
