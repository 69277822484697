<template>
  <v-card>
    <v-card
      class="container_main d-flex rounded-0 bg"
      width="100vw"
      min-height="100vh"
      height="wrap-content"
    >
      <div class="filter_bar">
        <v-card class="search_bar">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchKey"
            class="search_key"
          />
          <v-icon class="icons_search">{{ "mdi-filter-outline" }}</v-icon>
          <v-icon class="icons_search">{{ "mdi-magnify" }}</v-icon>
        </v-card>
        <v-card class="date_filter" @click="showDatePicker(0)">
          <v-icon class="icons_filter_date">{{
            "mdi-calendar-month-outline"
          }}</v-icon>
          <h5 class="title_type_date">From:</h5>
          <input type="date" v-model="fromDate" class="date_value_trans" />
          <h5 class="date_value">{{ new Date(fromDate).toDateString() }}</h5>
        </v-card>
        <v-card class="date_filter" @click="showDatePicker(1)">
          <v-icon class="icons_filter_date">{{
            "mdi-calendar-month-outline"
          }}</v-icon>
          <input type="date" v-model="toDate" class="date_value_trans" />
          <h5 class="title_type_date">To:</h5>
          <h5 class="date_value">{{ new Date(toDate).toDateString() }}</h5>
        </v-card>
      </div>
      <v-card class="results_container">
        <div class="banner">
          <h2 class="ml-5">My Jobs</h2>
        </div>
        <div class="table_of_results">
          <v-data-table
            :items-per-page="5"
            v-if="jobItems.length == 0"
            item-key="name"
            class="elevation-1 table-header table"
            loading
            :headers="headersNotfound"
            :items="itemNotfound"
            loading-text="Loading... Please wait"
            hide-default-footer
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              /> </template
          ></v-data-table>
          <v-data-table
            :items-per-page="5"
            v-else
            item-key="name"
            class="elevation-1 table-header table"
            loading
            :headers="jobTitle"
            :items="jobItems"
            loading-text="Loading... Please wait"
            hide-default-footer
            @click:row="handleRowClick"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              />
              <v-dialog v-model="dialog" max-width="750px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Information</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div v-for="(header, idx) in jobTitle" :key="idx">
                          <v-col cols="auto" sm="auto" md="auto">
                            <v-text-field
                              color="black"
                              v-if="checkIsEmpty(curItem[header.value])"
                              v-model="emptyItem"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                            <v-text-field
                              color="black"
                              v-else
                              v-model="curItem[header.value]"
                              :label="header.text"
                              :readonly="true"
                            ></v-text-field>
                          </v-col>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="close">
                      OK
                    </v-btn>
                    <!-- <v-btn color="blue darken-1" text @click="seeDetail">
                      See Preventative Job List
                    </v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template
              v-for="header in jobTitle"
              v-slot:[`item.${header.value}`]="{ item }"
            >
              <template v-if="typeof item[header.value] === 'boolean'">
                <div
                  class="checkbox_container text-start"
                  v-bind:key="item[header.value]"
                >
                  <v-checkbox
                    v-bind:key="item[header.value]"
                    v-model="item[header.value]"
                    :true-value="true"
                    :false-value="false"
                    light
                    class="chckbox"
                    disabled
                    color="black"
                  ></v-checkbox>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import DecodeJWT from "@/utils/DecodeJWT";
import { callBAQ } from "@/store/Method/index";
export default {
  components: {},
  watch: {},
  data: function () {
    return {
      fromDate: new Date(),
      toDate: new Date(),
      searchKey: "",
      headersNotfound: [{ text: "", value: "nf" }],
      itemNotfound: [{ nf: "Data not found!" }],
      jobItems: [],
      jobTitle: [],
      curItem: {},
      dialog: false,
    };
  },
  computed: {
    ...mapGetters("User", ["getUserInfo"]),
  },
  async mounted() {
    let token = localStorage.getItem("MTNToken");
    if (token == "" || !token) {
      this.$router.push("/login");
    } else {
      var info = DecodeJWT(token);
      if (info.exp <= new Date().getTime() / 1000) {
        this.$router.push("/login");
      } else {
        this.getData();
      }
    }
  },
  methods: {
    showDatePicker(type) {
      let inputs = document.getElementsByClassName("date_value_trans");
      inputs[type].showPicker();
    },
    close() {
      this.dialog = false;
    },
    handleRowClick(item) {
      this.curItem = item;
      this.dialog = true;
    },
    checkIsEmpty(value) {
      if (typeof value === "string") {
        if (value.length == 0) return true;
        return false;
      }
      if (typeof value === "boolean") {
        return false;
      }
      return false;
    },
    convertDate(stringdate) {
      if (stringdate) {
        var dateObject = new Date(stringdate);
        var day = dateObject.getDate().toString().padStart(2, "0");
        var month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        var year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
      } else return "";
    },
    async getData() {
      await callBAQ("DSP-MTN-GetSubcontractJobs", {
        VendorNum: "0",
      }).then((res) => {
        this.loading = false;
        if (res && res.message == "error") {
          alert(res.error);
        } else {
          let returnData = this.loadData(res);
          (this.jobTitle = returnData.h), (this.jobItems = returnData.i);
        }
      });
    },
    loadData(data) {
      let item = [],
        header = [];
      for (var title in data[0]) {
        header.push({
          text: title.split("_")[1],
          value: title,
          sortable: false,
        });
      }
      if (data && data.length > 0) {
        data.forEach((el) => {
          let i = {};
          for (var e in el) {
            if (e.includes("Date")) i[e] = this.convertDate(el[e]);
            else i[e] = el[e];
          }
          item.push(i);
        });
      }
      return { h: header, i: item };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/standard.scss";

.container_main {
  justify-content: center;
  height: wrap-content;
  flex-wrap: wrap;
  background-size: cover;
  background-color: #eef2f5;

  .filter_bar {
    width: 78vw;
    margin-top: 10px;
    height: 10vh;
    border: none;
    display: inline-flex;
    min-height: auto;

    .search_bar {
      width: 60%;
      height: 100%;
      border-radius: 8px;
      margin-right: 10px;

      .search_key {
        width: 80%;
        height: 60%;
        border-radius: 8px;
        padding-left: 30px;
      }

      .icons_search {
        height: 100%;
        width: 10%;
        font-size: 30px;
        color: rgb(89, 89, 89);
      }
    }

    .date_filter {
      width: 20%;
      height: 100%;
      border-radius: 8px;
      display: flex;
      align-items: center;

      .icons_filter_date {
        width: 20%;
        font-size: 30px;
        color: rgb(89, 89, 89);
      }

      .title_type_date {
        font-size: 15px;
        width: 20%;
      }

      .date_value_trans {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 999999;
      }

      .date_value {
        font-size: 15px;
        font-weight: 500;
        width: 60%;
      }

      &:first-child {
        margin: 0 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .results_container {
    width: 78vw;
    height: 85vh;
    border-radius: 8px !important;

    .banner {
      width: 100%;
      height: 50px;
      background-color: #0b5063;
      color: white;
      display: flex;
      align-items: center;
    }

    .table_of_results {
      min-width: 100%;
      height: calc(100% - 40px);

      .table {
        height: 100%;
        border-radius: 8px;
      }
    }
  }
}
</style>
