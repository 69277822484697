<template>
  <v-app style="background-color: #eef2f5">
    <v-main style="background-color: #eef2f5; width: 100vw">
      <aside
        :class="is_expanded ? 'is-expanded' : ''"
        style="position: fixed; z-index: 9999999"
      >
        <div class="menu">
          <router-link
            :to="url"
            style="background-color: #0b5063; height: fit-content"
            class="button d-flex justify-around align-center all-information"
          >
            <span
              ><v-icon class="d-flex material-icons">{{
                "mdi-account-circle-outline"
              }}</v-icon></span
            >
            <span
              class="text width-100 d-flex column justify-center align-center infomation"
            >
              Alex Thompson
              <br />
              NZA Distribution
              <br />
              07-3630 8600
            </span>
          </router-link>
          <div
            class="button d-flex justify-around button_menu"
            @click="toggleMenu"
          >
            <span
              ><v-icon class="d-flex material-icons">{{
                "mdi-menu"
              }}</v-icon></span
            >
            <span
              class="text width-100 d-flex justify-center button_menu align-center font-size-18"
              >Menu</span
            >
          </div>
          <div class="menu_list" @click="changeExpand(0)" v-show="isSupplier">
            <router-link
              @click="changeExpand(0)"
              to="/my-jobs"
              class="button d-flex justify-around menu-item"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons font-bold font-size-32">{{
                  "mdi-briefcase-variant"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center font-bold font-size-18"
                >My Jobs</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/time-sheet"
              class="button d-flex justify-around menu-item"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons font-size-32">{{
                  "mdi-clock-time-five-outline"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center font-bold font-size-18"
                >Time Sheet</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/dashboard"
              class="button d-flex justify-around menu-item"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons font-size-32">{{
                  "mdi-view-dashboard-outline"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center font-bold font-size-18"
                >Dashboard</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/history"
              class="button d-flex justify-around menu-item"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons font-size-32">{{
                  "mdi-history"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center font-bold font-size-18"
                >History</span
              >
            </router-link>
          </div>
        </div>
        <div class="flex"></div>
        <div class="menu">
          <router-link
            to="/"
            class="button d-flex justify-around align-center"
            style="background-color: #c2c3c3"
            :custom="true"
            v-slot="{ href }"
          >
            <a :href="href" @click="changeExpand(2)">
              <span
                ><v-icon class="d-flex material-icons">{{
                  "mdi-logout"
                }}</v-icon></span
              >
              <span
                class="text width-100 d-flex justify-center align-center font-size-18"
                >Log Out</span
              >
            </a>
          </router-link>
        </div>
      </aside>
      <div @click="closeMenu">
        <router-view
          :class="is_expanded ? 'is_expanded_route_content' : 'route_content'"
          style="background-color: #eef2f5"
        ></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      is_expanded: false,
      isSupplier: false,
      url: "/",
    };
  },
  mounted() {
    this.setURL();
    this.isSupplier =
      localStorage.getItem("isSupplierRole")?.toLowerCase?.() == "true";
  },
  computed: {
    ...mapGetters("User", ["getUserInfo", "getPermission"]),
  },
  methods: {
    setURL() {
      try {
        let url = window.location.href.split("#");
        this.url = url[1];
      } catch (e) {
        console.log(e);
      }
    },
    changeExpand(index = 0) {
      setTimeout(() => this.setURL(), 1000);
      index == 0
        ? (this.is_expanded = false)
        : this.$store.dispatch("User/logout");
    },
    toggleMenu() {
      this.is_expanded = !this.is_expanded;
    },
    closeMenu() {
      this.is_expanded = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css");
.align-center {
  display: flex;
  justify-items: center;
  align-items: center;
}
.color-black {
  color: black;
}
.width-100 {
  width: 100%;
}
.v-icon {
  color: black;
}
.menu_list {
  display: none;
}
.font-bold {
  font-weight: bold;
}
.font-size-32 {
  font-size: 32px;
}
.font-size-18 {
  font-size: 18px;
}

aside {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  width: calc(4rem);
  justify-content: center;
  overflow: hidden;
  min-height: 100vh;
  padding: 1rem;
  border: solid 1px #c1c1c1;

  .all-information {
    max-height: 100px;

    .material-icons {
      font-size: 32px;
      color: white !important;
    }
    .infomation {
      background-color: #0b5063;
      // background-color: #006780;
      color: white !important;
      flex-direction: column;
    }
  }

  .button_menu {
    max-height: 100px;
    cursor: pointer;

    .material-icons {
      font-size: 32px;
      color: black !important;
    }
  }

  span,
  router-link {
    text-decoration: none;
  }
  .button .text {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    margin-left: 5px;
  }
  .text {
    display: none;
    margin-left: 5px;
  }

  .menu {
    margin: 0 -1rem;

    .menu_list {
      .menu-item {
        border: solid rgb(121, 121, 121) 1px;
        margin: 20px;
        height: max-content;
        border-radius: 10px;

        &:hover {
          background-color: #0b5063;
          // background-color: #006780;
          .material-icons,
          .text {
            color: white !important;
          }
        }

        .material-icons {
          align-items: center;
          color: black;
          transition: 0.1s ease-in-out;
        }

        .text {
          color: black;
          transition: 0.1s ease-in-out;
        }
      }
    }

    .button {
      display: flex;
      height: 50px;
      align-items: center;
      text-decoration: none;
      transition: 0.1s ease-in-out;
      padding: 0.5rem 1rem;
    }
  }

  &.is-expanded {
    width: 20vw;

    .button .text {
      transition: 0.1s ease-in-out;
      opacity: 1;
    }

    .menu_list {
      display: flex;
      flex-direction: column;
      overflow: auto;
      max-height: 60vh;
      background-color: white;
      scrollbar-width: thin;

      .material-icons {
        align-items: center;
        color: black;
        transition: 0.1s ease-in-out;
      }
    }

    .button {
      height: 50px;
      .material-icons {
        margin-right: 1rem;
      }
    }

    .text {
      opacity: 1;
      color: black;
    }

    @media (max-width: 1000px) {
      width: 30vw;
      max-width: 25vw;
    }
    @media (max-width: 800px) {
      width: 100vw;
      max-width: 100vw;
    }
  }

  @media (max-width: 1024px) {
    position: absolute;
    z-index: 99;
  }
}
.child-flex > *,
.flex {
  flex: 1 1 auto;
  max-width: 100%;
}
.is_expanded_route_content {
  width: calc(80vw);
  margin-left: calc(20vw) !important;
}
.route_content {
  padding-left: calc(4rem);
}
</style>
